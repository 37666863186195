const UITEXT = {
    en: {
        'Weekly Pizza Quality Report': 'Weekly Pizza Quality Report',
        'Weekly Performance': 'Weekly performance',
        'Tips to Improve Your Pizzas': 'Tips to improve your pizzas',
        "Last Week's Production": "Last week's production",
        'Pizzas To Standard Trend': 'Pizzas to standard trend',
        'Weekly Quality Ranking - Pizzas To Standard': 'Weekly quality ranking - Pizzas to standard',
        'Stores Evolution': 'Stores evolution',
        "Last Week's Production (Scoreless)": "Last week's production (scoreless)",
        'Insights from 250 Pizzas per week': 'Insights from 250 pizzas per week',
        "Papa John's average for stores with over three months of data": "Papa John's average for stores with over three months of data",
        '(% Pizzas To Standard | Chart)': '(% Pizzas to standard | Chart)',
        '(% Pizzas to standard | Table)': '(% Pizzas to standard | Table)',
        'Store': 'Store',
        'Week': 'Week',
        'Report': 'Report',
        'Pizzas To Standard': 'Pizzas to standard',
        'To Standard': 'To standard',
        'Total Production': 'Total production',
        '∆ vs Last report': '∆ vs last report',
        'Lunch': 'Lunch',
        'Dinner': 'Dinner',
        'Average': 'Average',

        'January': 'January',
        'February': 'February',
        'March': 'March',
        'April': 'April',
        'May': 'May',
        'June': 'June',
        'July': 'July',
        'August': 'August',
        'September': 'September',
        'October': 'October',
        'November': 'November',
        'December': 'December',

        'Sunday': 'Sunday',
        'Monday': 'Monday',
        'Tuesday': 'Tuesday',
        'Wednesday': 'Wednesday',
        'Thursday': 'Thursday',
        'Friday': 'Friday',
        'Saturday': 'Saturday',

        'Monthly Quality Tracking': 'Monthly Quality Tracking',
        'Company performance': 'Company performance',
        'Stores ranking': 'Stores ranking',
        'Shifts performance': 'Shifts performance',
        'Stores evolution': 'Stores evolution',
        'Ranking of the most common pizza problems': 'Ranking of the most common pizza problems',
        'Evolution of the most common pizza problems': 'Evolution of the most common pizza problems',
        'Featured images': 'Featured images',
        '(% Pizzas to standard accumulated in the last month)': '(% Pizzas to standard accumulated)',
        '(% Pizzas to standard - Shift change at 5:00 PM)': '(% Pizzas to standard - Shift change at 5:00 PM)',
        '(% Pizzas to standard | Chart)': '(% Pizzas to standard | Chart)',
        '(% Pizzas to standard | Table)': '(% Pizzas to standard | Table)',
        '(% of times each attribute appeared)': '(% of times each attribute appeared)',
        '(monthly average attribute occurrences per store)': '(Monthly average attribute occurrences per store)',
        '(Examples of to standard and not to standard pizzas)': '(Examples of to standard and not to standard pizzas)',

        'No Data': 'No data',
        '% Pizzas to standard': '% Pizzas to standard',
        'Month': 'Month',
        '# Stores in report': '# Stores in report',
        'Reviewed Pizzas': 'Reviewed pizzas',
        'Pizzas to standard': 'Pizzas to standard',
        'Number of stores': 'Number of stores',

        'Best PJ Store': 'Best PJ store',
        'Weekly Average': 'Weekly average',
        'Best Store': 'Best store',
        'Company Average': 'Company average',

        'Pepperoni All Types': 'Pepperoni All Types',
        'Cheese All Types': 'Cheese All Types',
        'Works (Test)': 'Works (Test)',
        'Napolitan': 'Napolitan',
        'Meat': 'Meat',
        'Italian': 'Italian',
        'Pepperoni': 'Pepperoni',
        'BBQ Chicken and Sausage': 'BBQ Chicken and Sausage',
        'Sweet Chili Chicken': 'Sweet Chili Chicken',
        'Brasileira': 'Brasileira',
        'Frango com Requeijão': 'Frango com Requeijão',
        'Pesto Margarita': 'Pesto Margarita',
        'Food cost opportunities': 'Food cost opportunities',
        '(% Pizzas that exceed standard portions)': '(% Pizzas that exceeded standard portions)',
    },
    es: {
        'Weekly Pizza Quality Report': 'Informe Semanal de Calidad',
        'Weekly Performance': 'Resumen semanal',
        'Tips to Improve Your Pizzas': 'Tips para mejorar tus pizzas',
        "Last Week's Production": "Muestra de la producción de la semana pasada",
        'Pizzas To Standard Trend': 'Tendencia de pizzas estándar',
        'Weekly Quality Ranking - Pizzas To Standard': 'Ranking de calidad semanal - Pizzas estándar',
        'Stores Evolution': 'Evolución de las tiendas',
        "Last Week's Production (Scoreless)": "Producción de la semana pasada (sin nota)",
        'Insights from 250 Pizzas per week': 'Datos de 250 pizzas por semana',
        "Papa John's average for stores with over three months of data": "Promedio de Papa John's para tiendas con más de tres meses de data",
        '(% Pizzas To Standard | Chart)': '(% Pizzas estándar | Gráfico)',
        '(% Pizzas to standard | Table)': '(% Pizzas estándar | Tabla)',
        'Store': 'Tienda',
        'Week': 'Semana',
        'Report': 'Reporte',
        'Pizzas To Standard': 'Pizzas estándar',
        'To Standard': 'Estándar',
        'Total Production': 'Producción total',
        '∆ vs Last report': '∆ vs reporte anterior',
        'Lunch': 'Almuerzo',
        'Dinner': 'Cena',
        'Average': 'Promedio',

        'January': 'Enero',
        'February': 'Febrero',
        'March': 'Marzo',
        'April': 'Abril',
        'May': 'Mayo',
        'June': 'Junio',
        'July': 'Julio',
        'August': 'Agosto',
        'September': 'Septiembre',
        'October': 'Octubre',
        'November': 'Noviembre',
        'December': 'Diciembre',

        'Sunday': 'Domingo',
        'Monday': 'Lunes',
        'Tuesday': 'Martes',
        'Wednesday': 'Miércoles',
        'Thursday': 'Jueves',
        'Friday': 'Viernes',
        'Saturday': 'Sábado',

        'Monthly Quality Tracking': 'Seguimiento Mensual de Calidad',
        'Company performance': 'Resultados de la compañía',
        'Stores ranking': 'Ranking de tiendas',
        'Shifts performance': 'Desempeño por turno',
        'Stores evolution': 'Evolución de las tiendas',
        'Ranking of the most common pizza problems': 'Ranking de los problemas más comunes',
        'Evolution of the most common pizza problems': 'Evolución de los problemas más comunes',
        'Featured images': 'Imágenes destacadas',
        '(% Pizzas to standard accumulated in the last month)': '(% Pizzas estándar acumulado)',
        '(% Pizzas to standard - Shift change at 5:00 PM)': '(% Pizzas estándar - Cambio de turno a las 5:00 PM)',
        '(% Pizzas to standard | Chart)': '(% Pizzas estándar | Gráfico)',
        '(% Pizzas to standard | Table)': '(% Pizzas estándar | Tabla)',
        '(% of times each attribute appeared)': '(% de veces en que aparece cada atributo)',
        '(monthly average attribute occurrences per store)': '(Promedio mensual de ocurrencia de atributo por tienda)',
        '(Examples of to standard and not to standard pizzas)': '(Ejemplos de pizzas estándar y no estándar)',

        'No Data': 'Sin data',
        '% Pizzas to standard': '% Pizzas estándar',
        'Month': 'Mes',
        '# Stores in report': '# Tiendas en el reporte',
        'Reviewed Pizzas': 'Pizzas revisadas',
        'Pizzas to standard': 'Pizzas estándar',
        'Number of stores': 'Número de tiendas',

        'Best PJ Store': 'Mejor tienda PJ',
        'Weekly Average': 'Promedio semanal',
        'Best Store': 'Mejor tienda',
        'Company Average': 'Promedio de la compañía',

        'Pepperoni All Types': 'Pepperoni - Todos los tipos',
        'Cheese All Types': 'Queso - Todos los tipos',
        'Works (Test)': 'Works (Test)',
        'Napolitan': 'Napolitana',
        'Meat': 'Carne',
        'Italian': 'Italiana',
        'Pepperoni': 'Pepperoni',
        'BBQ Chicken and Sausage': 'BBQ Chicken and Sausage',
        'Sweet Chili Chicken': 'Sweet Chili Chicken',
        'Brasileira': 'Brasileira',
        'Frango com Requeijão': 'Frango com Requeijão',
        'Pesto Margarita': 'Pesto Margarita',
    }
}

const getCorrectUiText = (id, language) => {
    if (UITEXT[language] && UITEXT[language][id]) return UITEXT[language][id];
    return UITEXT.en[id] ?? 'ERR';
}

const getCorrectWeekUiText = (id, language) => {
    return `${language === 'es'? 'S': 'W'}${id.split('W')[1]}`
}

export {
    getCorrectWeekUiText,
    getCorrectUiText
}