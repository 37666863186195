import React, { useState, useEffect, useCallback } from 'react';
import style from './PizzaValidationScorelessReport.module.css'
import logoBlack from '../../../../vectors/logo_black.svg';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../common/loadingScreen/LoadingScreen';
import OrdinalDate from '../../../common/ordinalDate/OrdinalDate';
import ModalWrapper from '../../../common/modalWrapper/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DAYS, SHIFTS, reportPizzasToCards } from '../../Utils';
import StepSelector from '../../common/stepSelector/StepSelector';
import { sendScreenshots, getPagePictures, generatePDFWithSelenium } from '../../PDFGeneration';

const PizzaValidationScorelessReport = (props) => {
  const navigate = useNavigate();
  const { report, changeStep, autoPrint, afterPrint, weekStamp } = props;
  const [loading, setLoading] = useState(false);
  const [displayInspectedPicture, setDisplayInspectedPicture] = useState(false);
  const [inspectedPicture, setInspectedPicture] = useState(undefined);
  const [scorelessPages, setScorelessPages] = useState([]);
  const [blobs, setBlobs] = useState(undefined);
  const [reportReady, setReportReady] = useState(false);
  const [pizzasImages, setPizzasImages] = useState([]);
  const [forcePrint, setForcePrint] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [printing, setPrinting] = useState(false);
  const [readyForSelenium, setReadyForSelenium] = useState(false);

  useEffect(() => {
    return () => {
      setReportReady(false);
      setLoading(false);
      setScorelessPages([]);
      setBlobs(undefined);
      setPrinting(false);
      setTimerId(null);
      setForcePrint(false);
      setPizzasImages([]);
      setInspectedPicture(undefined);
      setDisplayInspectedPicture(false);
    }
  }, [])

  useEffect(() => {
    if (report === undefined) return;
    let d = reportPizzasToCards(report, true);
    setPizzasImages(Array(d.totalPizzas).fill(null).map(_ => ({ loaded: false })));
    setScorelessPages(d.cardsScoreless);
  }, [report])

  useEffect(() => {
    if (blobs === undefined) return;
    sendScreenshots(report, blobs, autoPrint, afterPrint, setLoading, undefined, navigate);
    // eslint-disable-next-line
  }, [blobs, report, navigate, autoPrint])

  useEffect(() => {
    if (printing) return;
    if (autoPrint && (reportReady || forcePrint)) {
      setPrinting(true);
      getPagePictures(blobs, setLoading, setBlobs, !forcePrint);
    }
  }, [autoPrint, reportReady, blobs, forcePrint, printing])

  const handleImageLoad = useCallback((index) => {
    setPizzasImages(prevData => {
      return prevData.map((obj, idx) => {
        if (idx === index) return { loaded: true };
        return { ...obj };
      })
    })
  }, [])

  useEffect(() => {
    if (pizzasImages.length === 0) return;
    for (let pizza of pizzasImages) {
      if (!pizza.loaded) return;
    }
    setReportReady(true);
  }, [pizzasImages])

  useEffect(() => {
    if (autoPrint && !reportReady) {
      const id = setTimeout(() => {
        if (!reportReady && !printing) {
          // window.alert(`Warning: ${report.doc.venue} report will be generated with some unloaded images.`);
          setForcePrint(true);
        }
      }, 30000);
      setTimerId(id);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line
  }, [reportReady]);

  useEffect(() => {
    if (reportReady) {
      setReadyForSelenium(true);
    }
  }, [reportReady])

  return (
    <LoadingScreen loading={loading}>
      {
        readyForSelenium ? (
          <div id='readyForSelenium' style={{display: 'none'}}></div>
        ) : (
          null
        )
      }
      {
        displayInspectedPicture ? (
          <ModalWrapper
            allScreen={true}
            fitContent={true}
            display={displayInspectedPicture}
            setDisplay={setDisplayInspectedPicture} >
            <div className={style.inspectedPictureWrapper}>
              <img className={style.inspectedPicture} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${inspectedPicture}`} alt='' />
              <div className={style.closeInspectedPictureButton} onClick={() => setDisplayInspectedPicture(false)}><span><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'black' }} icon={faPlus} /></span></div>
            </div>
          </ModalWrapper>
        ) : (
          null
        )
      }
      <div>
        {
          props.editable !== false ? (
            <span className={style.reportTitle}>Report Review - Store {report.doc.venue}</span>
          ) : (
            null
          )
        }
        {
          props.editable !== false ? (
            <StepSelector report={report} changeStep={changeStep}></StepSelector>
          ) : (
            null
          )
        }
        {
          props.editable !== false ? (
            <div className={`${style.flowButtons} invisibleForSelenium`}>
              <button className={style.flowButton} onClick={() => changeStep('data selection')}>{'< Back'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => getPagePictures(blobs, setLoading, setBlobs, false)}>{'Generate File'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => generatePDFWithSelenium([report], setLoading, navigate, weekStamp, false)}>{'Generate File (Selenium)'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => navigate('/pizza-tv-explorer')}>{'Finish'}</button>
            </div>
          ) : (
            null
          )
        }
        {/*
        // Cover with background image
        <div className={`${style.reportPage} pdfPage`} style={{ padding: 'inherit', backgroundImage: `url(${cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
        */}
        <div id='completeReport'>
          <div className={`${style.reportPage} pdfPage`} style={{ padding: 'inherit' }}>
            <img className={style.coverBrand} src={logoBlack} alt='kwali' />
            <div className={style.coverInfo}>
              <span className={style.coverInfoTitle}>Weekly Pizza Quality Report</span>
              <div className={style.coverInfoDescription}>
                <span>{report.doc.franchise}</span>
                <span>{`Store: ${report.doc.venue}`}</span>
                <span>{`Week ${report.doc.week} - Report ${report.doc.number}`}</span>
                <span><OrdinalDate date={new Date(report.week_stamp)} /> - <OrdinalDate date={new Date(new Date(report.week_stamp).getTime() + 6 * 24 * 60 * 60 * 1000)} />, {new Date(report.week_stamp).getFullYear()}</span>
              </div>
            </div>
          </div>
          {
            scorelessPages.map((scoreless, index0) => {
              return (
                <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} key={index0}>
                  {
                    index0 === 0 ? (
                      <React.Fragment>
                        <span className={style.pageTitle}>Last Week’s Production (Scoreless)</span>
                      </React.Fragment>
                    ) : (
                      null
                    )
                  }
                  {
                    Object.keys(scoreless).length > 0 ? (
                      Object.keys(scoreless).sort((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b)).map((day, index1) => {
                        return (
                          <div key={index1}>
                            {
                              Object.keys(scoreless[day]).sort((a, b) => SHIFTS.indexOf(a) - SHIFTS.indexOf(b)).map((shift, index2) => {
                                return (
                                  <div key={index2}>
                                    <span style={{
                                      border: shift === 'PM' ? '3px solid #4472c4' : '3px solid #f6c161',
                                      borderRadius: '50px',
                                      padding: '15px 0',
                                      fontSize: '30px',
                                      textAlign: 'center',
                                      marginBottom: '25px',
                                      marginTop: '25px',
                                      display: 'block',
                                      width: '400px'
                                    }}>{`${day} - ${shift}`}</span>
                                    <div className={style.shiftedCards}>
                                      {
                                        scoreless[day][shift].map((card, index3) => {
                                          return (
                                            <div className={style.card} key={index3}>
                                              <img onLoad={() => handleImageLoad(card.cardIndex - 1)} onClick={() => { setInspectedPicture(card.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                                              <span className={style.cardID}>{card.cardIndex}</span>
                                              <div className={style.cardInfo}>
                                                <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })
                    ) : (
                      null
                    )
                  }
                  <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                </div>
              )
            })
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default PizzaValidationScorelessReport;