import React from 'react';
import style from './StepSelector.module.css';


const StepSelector = (props) => {
  const { report, changeStep } = props;

  return (
    report ? (
      report.standard_scoreless_report ? (
        <div className={style.steps}>
          <span className={`${style.step} ${report.state === 'data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('data selection') }} >1</span>
          <div className={style.stepSeparator}></div>
          <span className={`${style.step} ${report.state === 'scoreless data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('scoreless data selection') }} >2</span>
          <div className={style.stepSeparator}></div>
          <span className={`${style.step} ${['complete', 'generated', 'delivered'].includes(report.state) ? style.activeStep : ''}`} onClick={() => { changeStep('complete') }} >3</span>
        </div>
      ) : (
        report.scoreless_report ? (
          <div className={style.steps}>
            <span className={`${style.step} ${report.state === 'data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('data selection') }} >1</span>
            <div className={style.stepSeparator}></div>
            <span className={`${style.step} ${['complete', 'generated', 'delivered'].includes(report.state) ? style.activeStep : ''}`} onClick={() => { changeStep('complete') }} >2</span>
          </div>
        ) : (
          <div className={style.steps}>
            <span className={`${style.step} ${report.state === 'data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('data selection') }} >1</span>
            <div className={style.stepSeparator}></div>
            <span className={`${style.step} ${['complete', 'generated', 'delivered'].includes(report.state) ? style.activeStep : ''}`} onClick={() => { changeStep('complete') }} >2</span>
          </div>
        )
      )
    ) : (
      null
    )
  )
}

export default StepSelector;