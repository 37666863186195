import React, { useState, useEffect, useCallback } from 'react';
import { FetchService } from '../../../services/Api';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import Header from '../../header/Header';
import style from './OperationControlCenter.module.css';


const OperationControlCenter = (props) => {
  const {
    whenSelectingWeek,
    selectedWeek
  } = props;
  const [loading, setLoading] = useState(false);
  const [OCCData, setOCCData] = useState(undefined);
  const [refreshing, setRefreshing] = useState(true);

  const getOCCData = useCallback(() => {
    if (selectedWeek === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: `pizza_tv/occ/week_stamp/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}/`,
      method: 'GET'
    }
    FetchService(requestConf)
      .then(response => {
        setOCCData(response.data);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false);
        setRefreshing(false);
      });
  }, [selectedWeek])

  useEffect(() => {
    if (selectedWeek === undefined) return;
    getOCCData();
  }, [selectedWeek])

  useEffect(() => {
    if (!refreshing) return;
    getOCCData();
  }, [refreshing])

  const refresh = useCallback(() => {
    setOCCData(undefined);
    setRefreshing(true);
  }, [])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <Header
          whenSelectingWeek={whenSelectingWeek}
          selectedWeek={selectedWeek}
        />
        <button className={style.refreshButton} onClick={() => refresh()}>Refresh</button>
        <div className={style.pageContent}>
          {
            OCCData ? (
              <div>
                <table style={{ borderCollapse: 'collapse', marginBottom: '15px' }}>
                  <tbody>
                    <tr style={{ backgroundColor: '#00225d', color: 'white' }}>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Total Stores</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Printed</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Sent</th>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', padding: '5px', border: '1px solid darkgrey' }}>{OCCData.resume_by_company.map(company => company.stores.length).reduce((acc, cur) => acc + cur, 0)}</td>
                      <td style={{
                        textAlign: 'center',
                        padding: '5px',
                        border: '1px solid darkgrey'
                      }}>{`${OCCData.printed_percentage ? (OCCData.printed_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                      <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${OCCData.sent_percentage ? (OCCData.sent_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ borderCollapse: 'collapse' }}>
                  <tbody>
                    <tr style={{ backgroundColor: '#00225d', color: 'white' }}>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Company</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>New Stores</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Stores</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Complete</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Printed</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Sent</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey', backgroundColor: '#0070c5' }}>LW Score</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey', backgroundColor: '#0070c5' }}>Print Score</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey', backgroundColor: '#0070c5' }}>Delta vs LW Score</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey', backgroundColor: '#0070c5' }}>Delta LW vs 4LW Score</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Highest Score increase</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Highest Score decrease</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Stores increasing</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Stores decreasing</th>
                    </tr>
                    {
                      OCCData.resume_by_company.sort((a, b) => a.printed_percentage > b.printed_percentage ? -1 : a.printed_percentage < b.printed_percentage ? 1 : 0).map((company, index) => {
                        return <tr key={index}>
                          <td style={{ textAlign: 'left', padding: '5px', border: '1px solid darkgrey' }}>{company.name}</td>
                          <td style={{
                            textAlign: 'center',
                            padding: '5px',
                            border: '1px solid darkgrey',
                            backgroundColor: company.new ? '#e68037' : 'white',
                            color: company.new ? 'white' : 'black'
                          }}>{`${company.new ? 'NEW' : 'STABLE'}`}</td>
                          <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{company.stores.length}</td>
                          <td style={{
                            textAlign: 'center',
                            padding: '5px',
                            color: 'white',
                            border: '1px solid darkgrey',
                            backgroundColor: company.complete === company.stores.length ? '#00b450' : '#ffc000'
                          }}>{company.complete}</td>
                          <td style={{
                            textAlign: 'center',
                            padding: '5px',
                            color: 'white',
                            border: '1px solid darkgrey',
                            backgroundColor: company.printed_percentage === 1 ? '#00b450' : company.printed_percentage > 0 ? '#ffc000' : '#f90201'
                          }}>{`${company.printed_percentage ? (company.printed_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                          <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${company.sent_percentage ? (company.sent_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                          {
                            company.lw.count > 0 ? (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${(company.lw.avg * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.cw.count > 0 ? (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${(company.cw.avg * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.cw.count > 0 && company.lw.count > 0 ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.cw.avg - company.lw.avg) > 0.06 ? '#f90201' : Math.abs(company.cw.avg - company.lw.avg) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.cw.avg - company.lw.avg) > 0.03 ? 'white' : 'inherit'
                              }}>{`${company.cw.avg - company.lw.avg > 0 ? '+' : ''}${((company.cw.avg - company.lw.avg) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company['5w'].count > 0 && company.lw.count > 0 ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.lw.avg - company['5w'].avg) > 0.06 ? '#f90201' : Math.abs(company.lw.avg - company['5w'].avg) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.lw.avg - company['5w'].avg) > 0.03 ? 'white' : 'inherit'
                              }}>{`${company.lw.avg - company['5w'].avg > 0 ? '+' : ''}${((company.lw.avg - company['5w'].avg) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.best_delta.store ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.best_delta.delta) > 0.06 ? '#f90201' : Math.abs(company.best_delta.delta) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.best_delta.delta) > 0.03 ? 'white' : 'inherit'
                            }}>{`${company.best_delta.delta > 0 ? '+' : ''}${((company.best_delta.delta) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.worst_delta.store ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.worst_delta.delta) > 0.06 ? '#f90201' : Math.abs(company.worst_delta.delta) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.worst_delta.delta) > 0.03 ? 'white' : 'inherit'
                            }}>{`${company.worst_delta.delta > 0 ? '+' : ''}${((company.worst_delta.delta) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            'positive_delta_percentage' in company ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                color: 'white',
                                backgroundColor: company.positive_delta_percentage > 0.5 ? '#f90201' : company.positive_delta_percentage > 35 ? '#ffc000' : '#00b450'
                            }}>{`${((company.positive_delta_percentage) * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            'negative_delta_percentage' in company ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                color: 'white',
                                backgroundColor: company.negative_delta_percentage > 0.5 ? '#f90201' : company.negative_delta_percentage > 0.35 ? '#ffc000' : '#00b450'
                            }}>{`${((company.negative_delta_percentage) * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            ) : (
              null
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default OperationControlCenter;