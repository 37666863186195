import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Report from './components/report/Report';
import Admin from './components/admin/Admin';
import Home from './components/home/Home';
import Login from './components/login/Login';
import Navbar from './components/navbar/Navbar';
import NotFound from './components/notFound/NotFound';
import Management from './components/report/management/Management';
import { FetchService } from './services/Api';
import PizzaTvWeekExplorer from './components/pizzaTv/pizzaTvWeekExplorer/PizzaTvWeekExplorer';
import PizzaValidation from './components/pizzaTv/pizzavalidation/PizzaValidation';
import MonthlyReportPreview from './components/pizzaTv/pizzavalidation/monthlyReportPreview/MonthlyReportPreview';
import PizzaTv from './components/pizzaTv/pizzaTv/PizzaTv';
import ReportControl from './components/pizzaTv/reportControl/ReportControl';
import OperationControlCenter from './components/pizzaTv/operationControlCenter/OperationControlCenter';

//eslint-disable-next-line
Number.prototype.commaRepresentation = function (fractionDigits) {
  let stringRepr = this.toFixed(fractionDigits);
  let integerPrt = stringRepr.split('.')[0];
  let commasCount = Math.floor(Math.max(integerPrt.length - 1, 0) / 3);
  let firstCommaPlace = (Math.max(integerPrt.length - 3, 0) % 3) || 3;
  let count = 0;
  while (count < commasCount) {
    let index = firstCommaPlace + 3 * count + count;
    stringRepr = stringRepr.slice(0, index) + ',' + stringRepr.slice(index);
    count += 1;
  }
  return stringRepr;
}

const App = () => {
  const [user, setUser] = useState(undefined);
  const [selectedVenue, setSelectedVenue] = useState(undefined);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [selectedCompanyPrettyName, setSelectedCompanyPrettyName] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [rawVenues, setRawVenues] = useState(undefined);
  const [venues, setVenues] = useState(undefined);
  const [venuesPrettyName, setVenuesPrettyName] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedWeekNumber, setSelectedWeekNumber] = useState(undefined);
  const language = (navigator.language || navigator.userLanguage).substring(0, 2);

  const fetchVenues = useCallback(() => {
    let requestConf = {
      endpoint: 'dashboard/venues/',
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setRawVenues(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [])

  useEffect(() => {
    if (window.location.pathname.includes('/pizza-tv/')) return;
    if (rawVenues === undefined) {
      fetchVenues()
    }
    else {
      setVenues(rawVenues.venues ? rawVenues.venues : undefined)
    }
  }, [rawVenues, fetchVenues])

  const [selectedDateRange, setSelectedDateRange] = useState({
    min: new Date(new Date().setHours(0, 0, 0, 0) - 86400000),
    max: new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
  });

  const [parsedSelectedDateRange, setParsedSelectedDateRange] = useState(undefined);

  useEffect(() => {
    if (selectedDateRange === undefined) return;
    setParsedSelectedDateRange({
      min: { day: selectedDateRange.min.getDate(), month: selectedDateRange.min.getMonth() + 1, year: selectedDateRange.min.getFullYear() },
      max: { day: selectedDateRange.max.getDate(), month: selectedDateRange.max.getMonth() + 1, year: selectedDateRange.max.getFullYear() }
    })
  }, [selectedDateRange])

  const whenSelectingVenue = useCallback((name, selection) => {
    if (selection.length !== 1) return;
    setSelectedVenue(selection[0].value);
  }, [])

  const whenSelectingCompany = useCallback((name, selection) => {
    if (selection.length !== 1) return;
    setSelectedCompany(selection[0].value);
    setSelectedCompanyPrettyName(selection[0].name);
  }, [])

  const whenSelectingDate = useCallback((name, selection) => {
    if (Object.keys(selection).length !== 3) return;
    setSelectedDate(selection);
  }, [])

  const whenSelectingDateRange = useCallback((name, selection) => {
    if (selectedDateRange &&
      selectedDateRange.min.toISOString().split('T')[0] === selection.min.toISOString().split('T')[0] &&
      selectedDateRange.max.toISOString().split('T')[0] === selection.max.toISOString().split('T')[0]) return;
    setSelectedDateRange(selection);
  }, [selectedDateRange])

  const whenSelectingWeek = useCallback((name, selection) => {
    setSelectedWeek(selection[0].value);
    setSelectedWeekNumber(selection[0].weekNumber);
  }, [])

  const whenSelectingMonth = useCallback((name, selection) => {
    setSelectedMonth(selection[0].value);
  }, [])

  const whenPrettyNames = useCallback((prettyNames) => {
    setVenuesPrettyName(prettyNames);
  }, [])

  useEffect(() => {
    if (user !== undefined || window.location.pathname.includes('/pizza-tv/')) return;
    let data = {
      endpoint: 'get_my_info',
      method: 'GET'
    }

    FetchService(data).then(
      (response) => {
        if (response && response.data) setUser(response.data);
      },
      (err) => {
        console.log(err);
      }
    )
  }, [user])

  return (
    <div className="App">
      <BrowserRouter>
        {
          user ? (
            <Navbar
              user={user}
              companyManager={user.company_manager} />
          ) : (
            null
          )
        }
        <Routes>
          {
            user ? (
              user.reports_only ? (
                <React.Fragment>
                  <Route exac path='' element={< Navigate to='/quality-reports' replace />} />
                  <Route exact path='quality-reports' element={<PizzaTvWeekExplorer
                    reportsOnly={true}
                    whenSelectingCompany={whenSelectingCompany}
                    selectedCompany={selectedCompany}
                    whenSelectingWeek={whenSelectingWeek}
                    selectedWeek={selectedWeek}
                    selectedWeekNumber={selectedWeekNumber} />} />
                  < Route exact path='live-pdf/venue/:venue/week/:week' element={<PizzaValidation language={language} editable={false} />} />
                  <Route path='*' element={<NotFound />} />
                </React.Fragment>
              ) : (
                user.daily_feed_only ? (
                  <React.Fragment>
                    <Route exac path='' element={< Navigate to='/daily-feed' replace />} />
                    <Route exact path='daily-feed' element={<Dashboard
                      user={user}
                      venues={venues}
                      selectedVenue={selectedVenue}
                      whenSelectingVenue={whenSelectingVenue}
                      whenSelectingCompany={whenSelectingCompany}
                      selectedCompany={selectedCompany}
                      selectedDate={selectedDate}
                      whenSelectingDate={whenSelectingDate}
                      language={language}
                      selectedDateRange={selectedDateRange}
                      parsedSelectedDateRange={parsedSelectedDateRange}
                      whenSelectingDateRange={whenSelectingDateRange} />} />
                    <Route path='*' element={<NotFound />} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Route
                      exac path=''
                      element={
                        ((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') ? (
                          user.company_manager ? (
                            < Navigate to='/management' replace />
                          ) : (
                            < Navigate to='/daily-insights' replace />
                          )
                        ) : (
                          < Navigate to='/daily-feed' replace />
                        )
                      }
                    />
                    <Route exact path='daily-insights' element={
                      <Home
                        language={language}
                        venues={venues}
                        selectedVenue={selectedVenue}
                        selectedDate={selectedDate}
                        whenSelectingVenue={whenSelectingVenue}
                        whenSelectingDate={whenSelectingDate} />} />
                    {
                      user.company_manager ? (
                        <Route exact path='management' element={
                          <Management
                            language={language}
                            selectedDateRange={selectedDateRange}
                            parsedSelectedDateRange={parsedSelectedDateRange}
                            whenSelectingDateRange={whenSelectingDateRange}
                            dateRangeMin={parsedSelectedDateRange.min}
                            dateRangeMax={parsedSelectedDateRange.max} />} />
                      ) : (
                        null
                      )
                    }
                    {
                      user.company_manager && (user.employer || user.has_company_memberships) ? (
                        <Route exact path='admin/:adminPage' element={<Admin user={user} />} />
                      ) : (
                        null
                      )
                    }
                    <Route exact path='report/:reportPage' element={<Report
                      language={language}
                      venues={venues}
                      selectedVenue={selectedVenue}
                      selectedDateRange={selectedDateRange}
                      parsedSelectedDateRange={parsedSelectedDateRange}
                      user={user}
                      whenSelectingVenue={whenSelectingVenue}
                      whenSelectingDateRange={whenSelectingDateRange} />} />
                    <Route exact path='login' element={<Login />} />
                    <Route exact path='test' element={<Dashboard
                      test={true}
                      user={user}
                      venues={venues}
                      selectedVenue={selectedVenue}
                      whenSelectingVenue={whenSelectingVenue}
                      whenSelectingCompany={whenSelectingCompany}
                      selectedCompany={selectedCompany}
                      selectedDate={selectedDate}
                      whenSelectingDate={whenSelectingDate}
                      language={language}
                      selectedDateRange={selectedDateRange}
                      parsedSelectedDateRange={parsedSelectedDateRange}
                      whenSelectingDateRange={whenSelectingDateRange} />} />
                    <Route exact path='daily-feed' element={<Dashboard
                      user={user}
                      venues={venues}
                      selectedVenue={selectedVenue}
                      whenSelectingVenue={whenSelectingVenue}
                      whenSelectingCompany={whenSelectingCompany}
                      selectedCompany={selectedCompany}
                      selectedDate={selectedDate}
                      whenSelectingDate={whenSelectingDate}
                      language={language}
                      selectedDateRange={selectedDateRange}
                      parsedSelectedDateRange={parsedSelectedDateRange}
                      whenSelectingDateRange={whenSelectingDateRange} />} />
                    {
                      user.employer && user.employer.pk === 'Kwali' ? (
                        <React.Fragment>
                          <Route exact path='pizza-tv-explorer' element={<PizzaTvWeekExplorer
                            whenSelectingCompany={whenSelectingCompany}
                            selectedCompany={selectedCompany}
                            whenSelectingWeek={whenSelectingWeek}
                            selectedWeek={selectedWeek}
                            selectedWeekNumber={selectedWeekNumber} />} />
                          <Route exact path='pizza-tv-month-explorer' element={<PizzaTvWeekExplorer
                            monthly={true}
                            whenSelectingCompany={whenSelectingCompany}
                            selectedCompany={selectedCompany}
                            whenSelectingMonth={whenSelectingMonth}
                            selectedMonth={selectedMonth} />} />
                          <Route exact path='report-control' element={<ReportControl
                            whenSelectingCompany={whenSelectingCompany}
                            selectedCompany={selectedCompany}
                            selectedCompanyPrettyName={selectedCompanyPrettyName}
                            whenSelectingWeek={whenSelectingWeek}
                            selectedWeek={selectedWeek}
                            whenSelectingVenue={whenSelectingVenue}
                            selectedVenue={selectedVenue}
                            whenPrettyNames={whenPrettyNames}
                            venuesPrettyName={venuesPrettyName}
                            selectedWeekNumber={selectedWeekNumber} />} />
                          <Route exact path='operation-control-center' element={<OperationControlCenter
                            whenSelectingWeek={whenSelectingWeek}
                            selectedWeek={selectedWeek} />} />
                          <Route exact path='live-pdf/venue/:venue/week/:week' element={<PizzaValidation language={language} editable={false} />} />
                          <Route exact path='pizza-tv-validation/venue/:venue/week/:week' element={<PizzaValidation language={language} />} />
                          <Route exact path='pizza-tv-print/company/:company/week/:week' element={<PizzaValidation language={language} printReports={true} />} />
                          <Route exact path='monthly-report/company/:company/month/:month' element={<MonthlyReportPreview language={language} />} />
                          <Route exact path='monthly-report/company/:company/month/:month/region/:region' element={<MonthlyReportPreview language={language} />} />
                        </React.Fragment>
                      ) : (
                        null
                      )
                    }
                    <Route path='*' element={<NotFound />} />
                  </React.Fragment>
                )
              )
            ) : (
              null
            )
          }
          <Route exact path='pizza-tv/venue/:venue/' element={<PizzaTv />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
